<template>
  <div>
    <CCard>
      <CCardBody>
        <CRow>
          <CCol>
            {{ $t('Start date') }}
            <MzDatePicker
              v-model="startDate"
              :config="{ format: $options.dateFormat }"
              @update:modelValue="fetchRentedBikes"
            />
          </CCol>
          <CCol>
            {{ $t('End date') }}
            <MzDatePicker
              v-model="endDate"
              :config="{ format: $options.dateFormat }"
              @update:modelValue="fetchRentedBikes"
            />
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CCardGroup columns class="card-columns cols-2">
      <CCard>
        <CCardBody>
          <CChartLine :data="rentedBikes" :class="{skeleton: loading}" />
        </CCardBody>
      </CCard>
      <CCard>
        <CCardBody>
          <CChartLine :data="registeredCasual?.workingday" :class="{skeleton: loading}" />
        </CCardBody>
      </CCard>
      <CCard>
        <CCardBody>
          <CChartBar :data="registeredCasual?.histogram" :class="{skeleton: loading}" />
        </CCardBody>
      </CCard>
    </CCardGroup>
  </div>
</template>

<script>
import { SampleService } from "@/services/sample";
import { CChartBar, CChartLine } from "@coreui/vue-chartjs";

export default {
  name: "SampleCharts",
  components: {
    CChartBar,
    CChartLine,
  },
  data() {
    return {
      rentedBikes: null,
      registeredCasual: null,
      loading: true,
      startDate: "2011-01-01",
      endDate: "2012-12-31",
    };
  },
  mounted() {
    this.fetchBikesData();
  },
  methods: {
    async fetchRentedBikes() {
      this.rentedBikes = await SampleService.fetchBikesChart(
        this.startDate,
        this.endDate
      );
    },
    async fetchBikesData() {
      this.fetchRentedBikes();
      this.registeredCasual = await SampleService.fetchRegisteredCasualChart();
      this.loading = false;
    },
  },
};
</script>
